import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import keys from 'lodash/keys';
import map from 'lodash/map';
import { Grid, Typography } from 'antd';
import PageLayout from 'src/components/Layout';
import useContentful from 'src/helpers/hooks/useContentful';
import ReviewsList from '../components/reviews/ReviewsList';
import PageTitle from '../components/PageTitle';

const { useBreakpoint } = Grid;
const { Title } = Typography;

export const query = `
{
  reviewsPage(id: "4Xw3hAmkVLEgTfgXNU5RXR") {
    pageHeader {
      pageTitle
      pagePhotoCollection {
        items {
          fileName
          url
          width
          height
        }
      }
    }
    reviews {
      reviews
    }
  }
}
`;

const ReviewsIndex = function ({ location }) {
  const { lg } = useBreakpoint();
  const { data, loading } = useContentful(query);
  const title = get(data, 'reviewsPage.pageHeader.pageTitle');
  const mainPhoto = get(data, 'reviewsPage.pageHeader.pagePhotoCollection.items');

  const reviews = get(data, 'reviewsPage.reviews.reviews');
  const reviewsYears = keys(reviews);

  return (
    <PageLayout
      location={location}
      title={title}
      photo={mainPhoto}
      loading={loading}
    >
      {lg && (
        <PageTitle
          title={title}
          style={{
            textAlign: 'center',
          }}
        />
      )}
      {map(reviewsYears, (year) => (
        <>
          <Title level={4}>{year}</Title>
          <ReviewsList data={reviews[year]} />
        </>
      ))}
    </PageLayout>
  );
};

ReviewsIndex.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ReviewsIndex;
