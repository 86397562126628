import React from 'react';
import PropTypes from 'prop-types';
import {
  Comment, Typography,
} from 'antd';

const { Text } = Typography;

const ReviewsListRow = function ({ data }) {
  return (
    <Comment
      author={data.title}
      content={(
        <Text italic>
          {data.review}
        </Text>
      )}
      actions={[
        <small
          key={data.review + data.reviewer}
          style={{ fontSize: '80%' }}
        >
          {data.reviewer}
        </small>,
      ]}
    />
  );
};

ReviewsListRow.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ReviewsListRow;
