import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import ReviewsListRow from './ReviewsListRow';

const ReviewsList = function ({ data }) {
  return (
    <List
      size='small'
      dataSource={data}
      renderItem={(item) => (
        <List.Item
          key={item.review}
          className='review-list__item'
        >
          <ReviewsListRow data={item} />
        </List.Item>
      )}
    />
  );
};

ReviewsList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ReviewsList;
